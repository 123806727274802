import { FixedNumber } from '@ethersproject/bignumber'
export const Zero = FixedNumber.from(0)
export const RareLevelColors = [
  { name: 'Mythic', color: '#C77220' },
  { name: 'Legendary', color: '#A90404' },
  { name: 'Epic', color: '#5E2677' },
  { name: 'Rare', color: '#1D591F' },
  { name: 'Uncommon', color: '#C59F1B' },
  { name: 'Common', color: '#593F2E' },
]
