import { walletStore } from '@/stores/wallet-store'
import { when } from 'mobx'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import goTo from 'vuetify/lib/services/goto'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/', redirect: '/summon' },
  {
    path: '/inventory',
    name: 'Inventory',
    component: () => import('../modules/my-character/pages/my-character.vue'),
  },
  {
    path: '/summon',
    name: 'Summon',
    component: () => import('../modules/mint/pages/mint.vue'),
  },
  {
    path: '/airdrop-treasury',
    name: 'Airdrop Treasury',
    component: () => import('../modules/airdrop/pages/airdrop-treasury.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition): any => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = Number(to.hash)
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
})

// router.beforeEach(async (to, from, next) => {
//   await when(() => walletStore.loaded)
//   next()
// })

export default router
