import { ChainType } from '@/blockchainHandlers'
// import { TokenInfoModel } from '@/models/token-info-model'
import moment from 'moment'
import web3 from 'web3'

class LocalData {
  get walletAddresses(): string[] {
    return JSON.parse(localStorage.getItem('walletAddresses') || '[]')
  }
  set walletAddresses(value: string[]) {
    localStorage.setItem('walletAddresses', JSON.stringify(value))
  }

  getWalletAddress() {
    const accounts = JSON.parse(localStorage.getItem(`walletAddresses_`) || '[]')
    // if (!accounts.includes(account)) accounts = [account, ...accounts]
    return accounts
  }

  saveWalletAddress(value: string[]) {
    // account = web3.utils.toChecksumAddress(account)
    localStorage.setItem(`walletAddresses_`, JSON.stringify(value))
  }

  // saveAccountTokens(account: string, chain: string, tokens: TokenInfoModel) {
  //   localStorage.setItem(
  //     `account_tokens_${chain}_${account}`,
  //     JSON.stringify({
  //       tokens,
  //       time: moment().toISOString(),
  //     })
  //   )
  // }

  getAccountTokens(account: string, chain: string) {
    const { tokens, time } = JSON.parse(localStorage.getItem(`account_tokens_${chain}_${account}`) || `{}`)
    return { tokens, time }
  }

  clear(): void {
    localStorage.clear()
  }

  get bnb2usdPrice() {
    return localStorage.getItem('bnb2usdPrice') || ''
  }

  set bnb2usdPrice(price: string) {
    localStorage.setItem('bnb2usdPrice', price)
  }

  saveTokenPrice(tokenAddress, price, priceImpact) {
    localStorage.setItem(`bsc_${web3.utils.toChecksumAddress(tokenAddress)}`, JSON.stringify({ price, priceImpact }))
  }

  getTokenPrice(tokenAddress) {
    return JSON.parse(localStorage.getItem(`bsc_${web3.utils.toChecksumAddress(tokenAddress)}`) || `{}`)
  }

  saveEthBalance(address, balance) {
    localStorage.setItem(`bsc_${web3.utils.toChecksumAddress(address)}`, balance)
  }

  getEthBalance(address) {
    return localStorage.getItem(`bsc_${web3.utils.toChecksumAddress(address)}`) || '0'
  }

  get lastChain(): ChainType | null {
    return localStorage.getItem('lastChain') as ChainType
  }
  set lastChain(value: ChainType | null) {
    if (value) localStorage.setItem('lastChain', value)
    else localStorage.removeItem('lastChain')
  }

  saveSolanaTokens(address, tokens) {
    address = address.toLowerCase()
    localStorage.setItem(`sol_tokens_${address}`, JSON.stringify({ date: moment().toISOString(), tokens }))
  }

  getSolanaTokens(address) {
    address = address.toLowerCase()
    return JSON.parse(localStorage.getItem(`sol_tokens_${address}`) || `{}`)
  }
}

export const localdata = new LocalData()
