























































































































import { Observer } from 'mobx-vue'
import { Component, Provide, Vue } from 'vue-property-decorator'
import { AppProvider, appProvider } from './app-providers'
import { walletStore } from './stores/wallet-store'

@Observer
@Component({
  components: {
    'snack-bar': () => import('@/components/snack-bar/snack-bar.vue'),
    alert: () => import('@/components/alert/alert.vue'),
    'global-loading': () => import('@/components/global-loading/global-loading.vue'),
    WalletDialog: () => import('@/components/wallet-dialog.vue'),
  },
})
export default class App extends Vue {
  @Provide() providers: AppProvider = appProvider

  wallet = walletStore
  chainId = process.env.VUE_APP_CHAIN_ID

  mounted() {
    this.providers.router = this.$router
    walletStore.start()
  }

  drawer = false

  copyAddress() {
    navigator.clipboard.writeText(this.wallet.account!)
  }
}
