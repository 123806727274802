var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-navigation-drawer',{staticClass:"px-2",attrs:{"app":"","temporary":false,"disable-resize-watcher":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":""}},[_c('v-list-item',{staticClass:"my-4",attrs:{"active-class":"primary--text","to":"/inventory"}},[_c('v-list-item-title',{staticClass:"nav-btn-text text-h4",attrs:{"block":"","depressed":"","large":"","rounded":"","color":"primary","outlined":""}},[_c('span',[_vm._v("INVENTORY")])])],1),_c('v-list-item',{staticClass:"my-4",attrs:{"active-class":"primary--text","to":"/summon"}},[_c('v-list-item-title',{staticClass:"nav-btn-text text-h4",attrs:{"block":"","depressed":"","large":"","rounded":"","color":"primary","outlined":""}},[_c('span',[_vm._v("SUMMON")])])],1),_c('v-list-item',{staticClass:"my-4",attrs:{"active-class":"primary--text","to":"/airdrop-treasury"}},[_c('v-list-item-title',{staticClass:"nav-btn-text text-h4",attrs:{"block":"","depressed":"","large":"","rounded":"","color":"primary","outlined":""}},[_c('span',[_vm._v("AIRDROP TREASURY")])])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',{staticClass:"my-4",attrs:{"active-class":"primary--text"}},[_c('v-list-item-title',_vm._g(_vm._b({staticClass:"nav-btn-text text-h4",attrs:{"block":"","depressed":"","large":"","rounded":"","color":"primary","outlined":""}},'v-list-item-title',attrs,false),on),[_c('span',[_vm._v("GAME")])])],1)]}}],null,false,577839387)},[_c('span',[_vm._v("Coming soon!")])]),_c('v-list-item',{staticClass:"d-flex flex-column align-stretch mt-4"},[_c('connect-metamask',{attrs:{"requiredChainId":_vm.chainId}},[(_vm.wallet.connected)?_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center connect-wallet border-radius-8 text-none py-2 px-5 transparent-bg",attrs:{"large":""}},'div',attrs,false),on),[_c('div',{staticClass:"primary--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.wallet.shortAccount)+" ")]),_c('v-img',{staticClass:"ml-3",attrs:{"src":require('@/assets/images/wallet.svg'),"max-width":"18px","contain":""}})],1)]}}],null,false,713240693)},[_c('WalletDialog')],1):_vm._e()],1)],1)],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-app-bar',{staticStyle:{"background":"#1a1a1a"},attrs:{"height":"64px","fixed":"","dark":""}},[_c('div',{staticClass:"d-flex align-center px-2"},[_c('v-app-bar-nav-icon',{staticStyle:{"z-index":"10"},on:{"click":function($event){_vm.drawer = !_vm.drawer}}})],1)]):_c('v-app-bar',{staticStyle:{"background":"#1a1a1a"},attrs:{"dark":"","fixed":""}},[_c('v-container',[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('v-img',{attrs:{"src":require('@/assets/icons/logo.png'),"max-width":"50px"}})],1),_c('v-btn',{staticClass:"text-none nav-btn-text d-none d-sm-flex ml-6 px-2",attrs:{"text":"","to":"/inventory"}},[_vm._v("INVENTORY")]),_c('v-btn',{staticClass:"text-none nav-btn-text d-none d-sm-flex ml-6 px-2",attrs:{"text":"","to":"/summon"}},[_vm._v("SUMMON")]),_c('v-btn',{staticClass:"text-none nav-btn-text d-none d-sm-flex ml-6 px-2",attrs:{"text":"","to":"/airdrop-treasury"}},[_vm._v("AIRDROP TREASURY")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-none nav-btn-text d-none d-sm-flex ml-6 px-2",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v("GAME")])]}}])},[_c('span',[_vm._v("Coming soon!")])]),_c('v-spacer'),_c('connect-metamask',{attrs:{"requiredChainId":_vm.chainId}},[(_vm.wallet.connected)?_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center connect-wallet border-radius-8 text-none py-2 px-5 transparent-bg",attrs:{"large":""}},'div',attrs,false),on),[_c('div',{staticClass:"primary--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.wallet.shortAccount)+" ")]),_c('v-img',{staticClass:"ml-3",attrs:{"src":require('@/assets/images/wallet.svg'),"max-width":"18px","contain":""}})],1)]}}],null,false,713240693)},[_c('WalletDialog')],1):_vm._e()],1)],1)])],1),_c('v-main',[_c('router-view')],1),_c('page-footer'),_c('snack-bar'),_c('alert'),_c('global-loading')],1)}
var staticRenderFns = []

export { render, staticRenderFns }