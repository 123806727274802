import { action, computed, observable, reaction } from 'mobx'
import VueRouter from 'vue-router'
import { apiService } from './service/api-service'
import { localData } from './stores/local-data'
import { walletStore } from './stores/wallet-store'

export class AppProvider {
  router!: VueRouter
  wallet = walletStore
  api = apiService

  @observable lightmode = localData.lightmode

  constructor() {
    reaction(
      () => this.lightmode,
      (mode) => (localData.lightmode = mode),
      { fireImmediately: true }
    )
  }
}

export const appProvider = new AppProvider()
